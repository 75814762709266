import { useActorStore } from '@/stores/actors';

export function heightLabel(height) {
  const feet = Math.floor(height / 12);
  const inches = height % 12;

  const msg = `${feet} ft.`;
  if (inches > 0) {
    return msg + ` ${inches} in.`;
  } else {
    return msg;
  }
}

export function resumeUrlsFor(actorId) {
  return useActorStore().retrieve(actorId)?.resumeUrls || [];
}

export async function fetchResumeUrls(actorId) {
  return await useActorStore().retrieveResumeUrls(actorId).catch(() => null);
}

export function actorName(actorId) {
  return useActorStore().retrieve(actorId)?.name;
}
